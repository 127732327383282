body {
  margin: 0;
  font-family: monospace;
  background-color: black;
  color: palegreen;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
