@media only screen and (max-width: 540px) {
  .field-container {
    display: flex;
    flex-direction: column;
    min-width: 260px;
    width: 58vw;
    max-width: 360px;
  }

  .fields-container {
    width: 59vw !important;
    min-width: 280px;
    max-width: 380px;
  }

  .button-container {
    width: 58vw;
    min-width: 280px;
    max-width: 380px;
  }

  .page-title {
    width: 60vw;
    min-width: 280px;
    max-width: 380px;
  }
}

.bg-red{
  background-color: red;
}
.bg-blue{
  background-color: blue;
}
.App {
  text-align: center;
  min-width: 530px;
  overflow-y: hidden;
  height: 100vh;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
}

header img {
  color: paleturquoise;
  width: 100%;
  z-index: 3;
  max-width: 1500px;
}

header .divider {
  height: 5px;
  width: 100%;
  background-image: radial-gradient(red,black, black);
}

/* body{
  padding-top:120px;
} */
.orange {
  color: orange
}

.teal {
  color: paleturquoise
}

.underline {
  text-decoration: underline;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.page-wrapper {
  position: fixed;
  height: 60vh;
  width: 80vw;
  border: solid 1px white;
  background-color: rgb(40, 40, 40);
  top: 25vh;
  z-index: 2;
  overflow-y: scroll;
}

.fields-container {
  display: flex;
  background-color: black;
  flex-direction: column;
  width: 400px;
  margin: auto;
  padding: 10px;
  border: solid 1px white;
}

.field-container {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.long-field-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.subtext {
  color: salmon;
  font-size: 12px;
  height: 30px;
  margin-top: 12px;
}

input,
textarea {
  background: black;
  border: solid .5px white;
  color: cyan;
  height: 25px;
}

.main-container {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  max-height: 90vh;

}

#page-container {
  background: black;
  width: 80vw;
  height: 70vh;
}

.matrix-container {
  width: 10vw;
  height: 90%;
}

.buttons-container,
.button-container {
  display: flex;
  margin-top: 35px;
}

.buttons-container {
  justify-content: space-between;
}

.button-container {
  justify-content: flex-end;

}

button {
  width: 150px;
  color: white;
  font-family: monospace;
  height: 25px;
}

.submit-button {
  border: solid greenyellow 1px;
  background-color: green;

}

.next-button {
  border: solid cornflowerblue 1px;
  background-color: darkblue;

}

.prev-button {
  border: solid lightgray 1px;
  background-color: grey;
}

.page-title {
  font-size: 36px;
  margin-bottom: 25px;
}

.row {
  display: flex;
  justify-content: center;
}

.hidden {
  display: none !important;
}

.footer-wrapper {
  position: absolute;
  background-color: rgb(40, 40, 40) !important;
  left: 0;
  bottom: 0;
  height: 15vh;
  width: 100%;
  background: black;
  z-index: 0
}

.top {
  z-index: 4;
}

.nav-bar {
  margin-top: 5vh;
  display: flex;
  font-size: 24px;
  width: 80vw;
  justify-content: space-evenly;
  z-index: 4;
}

.signin-btn {
  position: relative;
  bottom: 90vh;
  left: 5px;
  font-size: 24px;
  z-index: 4;
}

.black {
  background-color: black;
}

.content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 10px;
}

.content-row-title {
  padding: 6px
}

.btm-bdr {
  border-bottom: solid 1px gray;
}

.reject-app {
  background: darkred;
  border-radius: 20px;
  width: 75px;
}

.approve-app {
  background: olivedrab;
  border-radius: 20px;
  width: 75px;
}

.app-details {
  display: flex;
  justify-content: space-around;
  background: lightgray;
  padding-bottom: 10px;
}

.app-col {
  display: flex;
  flex-direction: column;
  width: 26vw;
  color: black;
}
.content-row-item{
  width: 40%;
  border-right: solid 1px gray;
  text-align: left;
  cursor:pointer
}

#schedule-table {
  width:100%;
}
.register-btn{
  width: 50px;
  border-radius: 25px;
  font-weight: bold;
}
.zoom-btn{
  width: 50px;
  border-radius: 25px;
  font-weight: bold;
}
.column-center{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-wrapper{
  display: flex;
  flex-direction: column;
  width: 400px; 
  align-items: space-between;
  justify-content: space-around;
  height: 100px;
  margin-top: 10vh;
  background: lightgrey;
}
.center-self{
  align-self: center;
}